
import { IAppState } from '@/store/modules/app/types';
import { IMember } from '@/types/members.types';
import { IProject } from '@/types/project.types';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { BatteryType } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { plcVersionDate, showInstallationWizardInfoPopup } from '@/utils/versionManagementUtils';
/**
 * Component that represent the popup with information about installation wizard
 */
@Component
export default class InstallationWizardInfoPopup extends Vue {
  @State('app') appState!: IAppState;
  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: IMember;
  @Action('projects/loadProject') loadProject!: (projectId: string) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('members/fetchMember') fetchMember!: (payload: { memberId: string; projectId: string }) => Promise<void>;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  dialog = false;
  dontShowAgain = false;

  get user() {
    return this.appState.user;
  }

  async closeAndSend() {
    // if checkbox is checked, update project meta to disable popup from showing again
    if (this.dontShowAgain) {
      await this.updateProject({
        ...this.project,
        meta: {
          ...this.project.meta,
          disableInstallationWizardPopup: true,
        },
      });
    }
    // close popup
    this.dialog = false;
  }

  handleStart() {
    // close popup
    this.dialog = false;
    // redirect to installation wizard
    this.$router.push({ path: `/projects/${this.$route.params.id}/installation-wizard` });
  }

  async closeDialog() {
    this.dialog = false;
  }

  async mounted() {
    await this.loadProject(this.$route.params.id);
    await this.fetchMembers(this.$route.params.id);
    await this.fetchMember({ memberId: this.user.id, projectId: this.$route.params.id });
    if (!this.project.meta) {
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    const plcUpdateTime = plcVersionDate(this.project).getTime();
    const timeStampToCheck = Math.floor(showInstallationWizardInfoPopup.getTime());
    const batterySystemTypeForPopup = [BatteryType.DEYE, BatteryType.STANDALONE]; // battery system types for which the popup should be shown. must be updated if new systems get added    // check if project was created after the release of the popup
    if (plcUpdateTime > timeStampToCheck) {
      // check if battery system type is Deye
      if (batterySystemTypeForPopup.includes(this.project?.meta?.controller?.batterySystemType)) {
        // check if user is admin, popup is not disabled and installation wizard was not completed
        if (this.currentMember.role === 1000 && this.project?.meta?.disableInstallationWizardPopup !== true && this.project?.meta?.wasInstallationWizardCompleted !== true) {
          // if all conditions are met, show popup after 1 seconds inside the project
          setTimeout(() => {
            this.dialog = true;
          }, 1000);
        }
      }
    }
  }
}
